import React from 'react';
import styled from 'styled-components';

const ProjectSeoul = () => {

    return (
        <ProjectSeoulStyled className='ProjectSeoul'>
            <h2>The Soul of Seoul</h2>
            <div className="intro">
                <ul>
                    <p>
                        <b>Whether it's for travel or long term, many people like to plan ahead and be informed about new places they are planning to go. The best way to find out about a new place is learning from someone who actually lives there. The Soul of Seoul started off as just a travel blog but has now blossomed into a detailed guide to travelling and living in not just Seoul, but all of South Korea. I had the opportunity to help improve this site in order for future travellers to take advatage of its plethora of information.</b>
                    </p>
                </ul>
            </div>
            <div className="body">
                <ul>
                    <p>
                    The site provides blog entries that describe what it is like living as an expat in Seoul, South Korea. It provides information on future events and hidden gems all around the country. It also has guided tours that visitors can sign up for. 
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ContentStrategy/The-Soul-of-Seoul-no-background.webp" alt="The Soul of Seoul Logo"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>The Problem</b></h3>
                        After over ten years it was about time for the site to look at all the content it has made, make sure that everything is up to date and organized, and fix some designing issues. These changes need to be made to increase the number of visitors, returning visitors, and exposure on search engines.
                    </p>
                    <p>
                        The content audit we conducted showed that there were <b>some categories that needed to be modified</b> and several blog posts that became <b>redundant</b> over the years. We also found that the site's <b>design has become cheap and cluttered</b> and that it has a <b>very small presence in search engine results</b>.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ContentStrategy/seoul_audit.jpg" alt="The Soul of Seoul Content Audit"/>
            <div className="body">
                <ul>
                    <p>
                        The audit also showed that the site is targeting two types of users. A primary and secondary persona were created to help with remembering who the site should be focusing on. The first persona represents tourists who are visiting Seoul for vacation, and the second persona represents expats who are planning on staying in South Korea for a long time. The two personas' journeys were also made to show how they would discover the site and use the site.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ContentStrategy/seoul_personas.jpg" alt="The Soul of Seoul Personas"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>What Does the Research Tell Us?</b></h3>
                        I decided that in order to improve the site and its SEO changes need to be made in the Discover and Learn phases of the user's journey.
                    </p>
                    <p>
                        <h3><b>Main Page</b></h3>
                        The main page is the first page a user accesses when going to the site. The content should be easy to read, easy to access, and easy to loot at. It should also distinguish the two target audiences in a clearer way. The problem isn't about the quality of the content, but how it's organized and distributed.
                    </p>
                    <p>
                        To achieve this goal I recommended that the site increase more overall whitespace by redesigning the layout, modifying how the advertisements are displayed, and resizing the images. Also, the site needs to declutter the page by reducing the amount of internal links, reorganizing content into more reading sections, and rearranging content to engage top-of-funnel users.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ContentStrategy/seoul_mainpage.jpg" alt="Main Page Future State"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>Starter Guide</b></h3>
                        The Starter Guide might have once been used for something but now it just links back to the main page. I recommended that the site should bring relevant links from the Main Page to the Starter Guide page to reduce clutter from the Main Page and make sure of the Starter Guide page. Then, they should arrange the relevant links to show a top-to-bottom funnel structure.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ContentStrategy/seoul_starterguide.jpg" alt="Starter Guide Future State"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>Blog Posts</b></h3>
                        To make blog posts more readable and reachable, I recommended that the margin frames should be decreased and that the more wordy sections should be spaced out into more readable chunks to make it look less wordy. I also recommended that there should be an ability to share blog post articles and to put more emphasis on keywords and to try to go easy on the ads.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ContentStrategy/seoul_marginspace.jpg" alt="Margins and Spacing"/>
            <img src="/assets/img/Work/ContentStrategy/seoul_sharingads.jpg" alt="Sharing and Ads"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>Some More Tweaks</b></h3>
                        Along with organizing content and improving SEO, I also recommend some tweaks to help improve the design and usability of the site.
                    </p>
                    <p>
                        <h3><b>Change the Link Style</b></h3>
                        I recommended that the site should make links more appealing with images and to add sharing options on the link. This will help keep the user on the site and help bring in more new users.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ContentStrategy/seoul_links.jpg" alt="Link Style"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>UX Writing</b></h3>
                        I also made some changes in the UX writing to make the tone more friendly. It helps the site seem less rigid and more casual.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ContentStrategy/seoul_uxwriting01.jpg" alt="UX Writing 01"/>
            <img src="/assets/img/Work/ContentStrategy/seoul_uxwriting02.jpg" alt="UX Writing 02"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>Optional Chatbot</b></h3>
                        I also gave the site a glimpse of what a chatbot would look like with a chatbot flow if they wanted to have this option in the future.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ContentStrategy/seoul_chatbotflow.jpg" alt="Persona Chatbot Flow"/>
            <div className="body">
                <ul>
                    <p>
                        With these changes I believe The Soul of Seoul would benefit by gaining more repeat visits to the Main Page and stay engaged longer on the site. I believe that traffice will increase and the site will have a healthy bounce rate. The users will post better reviews and help the site get higher on the SEO list.
                    </p>
                </ul>
            </div>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>Results</b></h3>
                        The site responded very well with the proposed changes. It was good to see that changes were being made more changes according to my recommendations are to come.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ContentStrategy/seoul_oldnew.jpg" alt="Old Site to New Site"/>
        </ProjectSeoulStyled>
    );
}

export default ProjectSeoul;

const ProjectSeoulStyled = styled.div`
    
    font-family:"Raleway";
    font-size: 30px;

    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
    }

    h2 {
        font-family: "Raleway";
        font-size: 60px;
        margin: 50px 350px 200px 350px;
        color: dodgerblue;
    }

    .intro {
        margin: 200px 350px 0px 350px;
        font-size:35px;
    }

    .body {
        margin: 100px 350px 100px 350px;
        font-size: 30px;
            
            h3 {
                font-family: "Helvetica";
                font-size: 30px;
            }
    }
`;