import React from 'react';
import styled from 'styled-components';

const ProjectAmelio = () => {

    return (
        <ProjectAmelioStyled className='ProjectAmelio'>
            <h2>Project Amelio</h2>
            <div className="intro">
                <ul>
                    <p>
                        <b>Sometimes business ideas don't end up coming to fruition. But even in those situations you can learn from the experience and improve for the next project. Project Amelio was supposed to be an English teaching app that revolved around a textbook created by the owner. It was supposed be a fresh, new way to learn English.</b>
                    </p>
                </ul>
            </div>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>The Client</b></h3>
                        My client was my former manager when I was an English instructor in Korea. After working in her company for serval years she wanted to set off on her own and start her own English education business. She came to me with many ideas and hoped to used my teaching background and UX skills to help her create her app that she believed would appeal to students more than what was already out there. The client and I were creating a business from the ground up and the first step was to get financial support from the South Korean government. In order to do that the client had to create a proposal with evidence showing that this business was worthy of investment.
                    </p>
                    <p>
                        This project made me realize that <b>there is more to UX than just research and design. It exposed me to the business and client interacting side of UX.</b>
                    </p>
                </ul>
                <ul>
                    <p>
                        <h3><b>Managing and Organizing the Ideas</b></h3>
                        Like many people who are starting businesses, my client was overflowing with ideas that she wanted to incoporate into her app. I had to calm her down and try to get her to understand that since this company was just starting, we won't be able to include all her ideas into her app.
                    </p>
                    <p>
                        I suggested creating an assumptions grid to organize our ideas, see which are high risk/low risk, and choose a handful that we want to focus on. The client understood and agreed to focus on these ideas for the time being.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/UX/AmelioAssumpGrid.jpg" alt="Assumptions Grid"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>Research</b></h3>
                        After much convincing I got the client to agree to conduct research to find out if the ideas she came up with were actually things that users wanted. We came up with a problem to focus on: <b>How do we make English education more compelling when the student is not in the same classroom as the teacher?</b>  
                    </p>
                    <p>
                        <h3><b>Competitive Analysis</b></h3>
                        I began competitive alaysis to see what other apps were doing, if they had the same ideas, if we could improve on them, and if any of my client's ideas were new and unique. 
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/UX/AmelioCompAnaly.jpg" alt="Competitive Analysis"/>
            <div className="body">
                <ul>
                    <p>
                        I showed the client my competitive analysis results to help her realize that her ideas were not as unique as she believed them to be but also to reassure her that her vision for her product was achievable as long as we look at our capability more realistically and focus on what we can do at the moment versus what we want to do, but don't have the proper resources to accomplish yet.
                    </p>
                </ul>
                <ul>
                    <p>
                        <h3><b>Shifting Focus</b></h3>
                        I was able to show the importance of the users' perspective to the client and suggested that we reach out to students and ask them how they felt about the English learning apps and classes that already existed and what they wanted that was different. This was to not only see what students actually wanted in their English learning experience and see what the existing products were missing, but it was to also see if my client's ideas were actually things students wanted.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/UX/AmelioSurveyLink.png" alt="Survey"/>
            <div className="body">
            <ul>
                    <p>
                        We started off with a survey that asked simple questions to get a feel of how the students felt about existing products and their experience learning English. Then with the information we gathered, I created a list of questions for user interviews to get more qualitative information on students' experiences and wants so that we can start focusing on creating our product.
                    </p>
                    <p>
                        During this time the client was in the process of proposing her business to the South Korean government for financial support using the information we gathered so far. 
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/UX/Ameliostdntqust.jpg" alt="User Interview Questions"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>Suddent Shutdown</b></h3>
                        I was preparing to start finding participants for the user interview questions when suddenly my client decided to pull the plug on the entire business. During our final meeting she said she decided not to continue and needed to change the direction of the business. She thanked me for my help and that was the end. 
                    </p>
                    <p>
                        <h3><b>What I Learned</b></h3>
                        This was a very valuable experience to me because it was the first time experiencing a job that didn't end up producing anything. You can do the work and feel like things are going well, but the project can still get shut down without notice. I have to accept that some of the things I do will end up producing nothing.
                    </p>
                    <p>
                        This also gave me vauable experience in dealing with clients. UX is not just about researching and designing products that users want to use, but it is also about making sure the client is happy. I had to learn how to manage my client's expectations and control her ambitious energy. I also had to do a lot of convincing on how important it is to do the UX research before creating prototypes. I don't think learning how to deal with clients is something that can be taught. It is through experience that a UX designer learns that it is a big part of their career, and learning how to communicate with your client will be very beneficial to their success.
                    </p>
                </ul>
            </div>
        </ProjectAmelioStyled>
    );
}

export default ProjectAmelio;

const ProjectAmelioStyled = styled.div`

font-family:"Raleway";
    font-size: 30px;

    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
    }

    h2 {
        font-family: "Raleway";
        font-size: 60px;
        margin: 50px 350px 100px 350px;
        color: dodgerblue;
    }

    .intro {
        margin: 100px 350px 0px 350px;
        font-size: 35px;
    }

    .body {
        margin: 100px 350px 100px 350px;
        font-size: 30px;
            
            h3 {
                font-family: "Helvetica";
                font-size: 30px;
            }
    }
`;