import React from 'react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';

import Home from '../Home/Home.jsx';
import About from '../About/About.jsx';
import Work from '../Work/Work.jsx';
import UX from '../Extra/UX/UX.jsx';
import Homework from '../Extra/Homework/Homework.jsx';
import ProjectFischbach from '../Projects/ProjectFischbach.jsx';
import ProjectAirbnb from '../Projects/ProjectAirbnb.jsx';
import ProjectRoominate from '../Projects/ProjectRoominate.jsx';
import ProjectSeoul from '../Projects/ProjectSeoul.jsx';
import ProjectReels from '../Projects/ProjectReels.jsx';
import ProjectAmelio from '../Projects/ProjectAmelio.jsx';
import ScrollToTop from '../ScrollToTop'

const Main = () => {

    return (
        <MainStyled className='Main'>
            <ScrollToTop />
                <Switch>
                    <Route path='/' exact>
                        <Home />
                    </Route>
                    <Route path='/projectfischbach' >
                            <ProjectFischbach />
                        </Route>
                        <Route path='/projectRoominate'>
                            <ProjectRoominate />
                        </Route>
                        <Route path='/projectairbnb'>
                            <ProjectAirbnb />
                        </Route>
                        <Route path='/projectseoul'>
                            <ProjectSeoul />
                        </Route>
                        <Route path='/projectreels'>
                            <ProjectReels />
                        </Route>
                        <Route path='/projectAmelio'>
                            <ProjectAmelio />
                        </Route>
                    <div className="inset">
                        <Route path='/about'>
                            <About />
                        </Route>
                        <Route path='/work'>
                            <Work />
                        </Route>
                        <Route path='/ux'>
                            <UX />
                        </Route>
                        <Route path='/homework'>
                            <Homework />
                        </Route>
                    </div>
                </Switch>
        </MainStyled>
    );
}

export default Main;

const MainStyled = styled.main`
        
`;