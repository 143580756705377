import { NavLink } from 'react-router-dom';

export const workData =  {
    categories: ['All', 'UX', 'Content Strategy', 'Research', 'Service Design'],
    work: [
        { id: 1, image: '/assets/img/Work/UX/Roominate.jpg', title: 'Roominate', category: 'UX', description: 'A student UX project of an application for furniture rearrangement and room planning.', link: <NavLink to={'/projectroominate'}>Roominate</NavLink> },
        { id: 2, image: '/assets/img/Work/UX/Airbnb.png', title: 'Airbnb', category: 'UX', description: 'A student project on an Airbnb case study about the inequality of attention Airbnb gives to the hosts and guests. ', link: <NavLink to={'/projectairbnb'}>Airbnb</NavLink> },
        { id: 3, image: '/assets/img/Work/ServiceDesign/Fischbach.jpg', title: 'Fischbach Experience Center', category: 'Service Design', description: 'A student group project on how to bring customers back to brick and mortar car dealerships.', link: <NavLink to={'/projectfischbach'}>Fischbach Experience Center</NavLink> },
        { id: 4, image: '/assets/img/Work/ContentStrategy/SoulofSeoul.png', title: 'The Soul of Seoul', category: 'Content Strategy', description: 'A freelance project that started off as a student project on content strategy and UX design of a Seoul, South Korea traveling blog.', link: <NavLink to={'/projectseoul'}>The Soul of Seoul</NavLink> },
        { id: 5, image: '/assets/img/Work/Research/reels.jpg', title: 'Instagram Reels UX Research', category: 'Research', description: 'A student research project on the performance of the Instagram Reels feature.', link: <NavLink to={'/projectreels'}>Instagram Reels Research</NavLink> },
        { id: 6, image: '/assets/img/Work/UX/Amelio.jpg', title: 'Project Amelio', category: 'UX', description: 'An English teaching app project that was at the very beginning of its formation but ended up shutting down.', link: <NavLink to={'/projectAmelio'}>Project Amelio</NavLink> },
    ],
}