import React from 'react';
import styled from 'styled-components';

const ProjectRoominate = () => {

    return (
        <ProjectRoominateStyled className='ProjectRoominate'>
            <h2>Roominate</h2>
            <div className="intro">
                <ul>
                    <p>
                        <b>People are moving to new homes all the time. Others feel like it's time to give their rooms a new look whether it's for spring cleaning or just for a change. Regardless of the reason, moving and rearranging can be a long and arduous task.  One of the biggest time and engery consumers is moving furniture. </b>
                    </p>
                </ul>
            </div>
            <div className="body">
                <ul>
                    <p>
                        The <b>Roominate</b> app allows users to plan how they are going to arrange and set up their rooms without having to move their heavy furniture. With AR technology the app scans their room and furniture and lets users move the furniture around unti they have found the perfect spot for it. It gives users a visual template of where they will need to move their furniture so that they only need to do it once.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/UX/roominate_main.jpg" alt="Main"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>What Needs Fixing?</b></h3>
                        For this project I needed to find a problem that I could solve and create an app for. At the time I was not happy with the way my apartment was set up and kept moving furniture around until I was satisfied with the new arrangement. I got tired from the heavy lifting before I was able to fight the perfect setup and wondered if other people had the same problem.
                    </p>
                    <p>
                        I came up with a research plan that included interview questions and began conducting user interviews to see if this is actually a problem people face and if there were other aspects that I missed.
                    </p>
                    <p>
                        To my surprise, I found that many people rearrange their furniture often and even have the same issue when moving to a new home. 
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/UX/roominate_research.jpg" alt="Research Plan and Interviews"/>
            <div className="body">
                <ul>
                    <p>
                        After the interviews and knowing my target users I knew what my app's main focus was going to be. I decided to conduct competative analysis and see what similar apps were already doing. I found that while the competition had options for blueprints and 3D views of the rooms, only one app had a way to use your actual room. However, the one app that let you use your actual room was a furniture shopping app.
                    </p>
                    <p>
                        I knew then that my app's main focus would be to allow users to set up their actual room using their device's camera and AR technology. I also made a feature prioritization map from the competitive analysis to help me decide what other features the app will have.
                    </p>
                    <br></br>
                    <p><h3><b>Creating the App</b></h3>
                    With the information I gathered from my research I had a good understanding of what my app will consist of. So now I had to plan the structure and flow of the app. The app had to make sure that it was doing it main task and the user can access it easily and conveniently. Also, I wanted to makes sure that the app wasn't filled with too many unnecessary features.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/UX/roominate_flowmap.jpg" alt="App Map and User Flow"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>Small Snag</b></h3>
                        When I was in the wireframing phase of my app I realized that AR technology might not be advanced enough to actually do what the app requires and I will not be able to use it when conducting usability tests. I decided that I will have to try to simulate the AR technology when making my prototypes and if the app ever gets made I will figure out if AR technology will actually be possible for this app. After my paper prototype usability tests I made some tweaks and started to make my prototype.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/UX/roominate_wireproto.jpg" alt="Wireframe and Prototypes"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>Usability Testing</b></h3>
                        I tried to test users of different age groups and marital statuses to see if the app was useful and easy to use for everyone. I found users who were in the retired age group, new families who just moved into a new home, and single people who live in small apartments. I conducted the usability tests and recorded and analyzed the results.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/UX/roominate_usertests.jpg" alt="Usability Tests and Results"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>Results</b></h3>
                        The user results were generally positive. They liked the simple design and were able to complete most of the tasks without any problems. However, not all users were familiar with AR mode so there was a little learning curve for them. There were some minor cosmetic complaints, which can be corrected quickly, and some wanted labels and directions to be clearer. Overall, I would say the first iteration of my app was a success.
                    </p>
                </ul>
            </div>
        </ProjectRoominateStyled>
    );
}

export default ProjectRoominate;

const ProjectRoominateStyled = styled.div`
        
    font-family:"Raleway";
    font-size: 30px;

    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
    }

    h2 {
        font-family: "Raleway";
        font-size: 60px;
        margin: 50px 350px 200px 350px;
        color: dodgerblue;
    }

    .intro {
        margin: 200px 350px 0px 350px;
        font-size:35px;
    }

    .body {
        margin: 100px 350px 100px 350px;
        font-size: 30px;
            
            h3 {
                font-family: "Helvetica";
                font-size: 30px;
            }
    }
`;