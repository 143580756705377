import React from 'react';
import styled from 'styled-components';

const ProjectReels = () => {

    return (
        <ProjectReelsStyled className='ProjectReels'>
                        <h2>Instagram Reels Research</h2>
            <div className="intro">
                <ul>
                    <p>
                        <b>Instagram is a widely popular social media app that many of you are probably familiar with. Over the years it has grown tremendously and has added new features to keep it fresh, while giving users new ways to post their content. One of the newest features is called Reels.</b>
                    </p>
                </ul>
            </div>
            <div className="body">
                <ul>
                    <p>
                    Reels was introduced to the public on August 2020 and has been slowly gaining popularity. Instagram describes Reels as a functionality where you can “record and edit 15-second multi-clip videos with audio, effects, and new creative tools.” 
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/Research/Reels/instagram-reel.png" alt="Instagram Reels Logo"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>The Problem</b></h3>
                        At the time of this study it has been about 6 months since Reels launched and I wanted to observe how Reels is being perceived by Instagram users and how Instagram could entice more users to embrace it.
                    </p>
                    <p>
                        I believed that if Reels failed to succeed and loses support, Instagram could suffer from losses in revenue and popularity from its competitors. Therefore, It was important to study the users’ attitudes and experiences in order to improve on this functionality.
                    </p>
                    <p>
                        <b>The Problem Statement:</b> As an Instagram user I want to familiarize myself with the new functionality, Reels, so that I can expand the way I post my content, but I am hesitant to try it.
                    </p>
                    <p>
                        My study goals were:
                        <li>To identify what kinds of users are using Reels and why others aren’t.</li>
                        <li>To observe how new users interact with Reels and determine what problems and pain points come about when trying out this new functionality.</li>
                        <li>Determine what can be improved in this new feature and how to entice hesitant users to start using Reels.</li>
                    </p>
                </ul>
                <ul>
                    <p>
                        <h3><b>Methodology</b></h3>
                        Due to the COVID-19 pandemic, there were some limitations on interacting with participants, so I had to keep that in mind when choosing the research methods.
                    </p>
                    <p>
                        The research methods I decided to use for this study were <b>Heuristic Evaluation, User Interviews, and Think-Aloud Usability Testing</b>.
                    </p>
                    <p>
                        <h3><b>Heuristic Evaluation</b></h3>
                        Heuristic Evaluation allowed me to look at the functionality through a UX lens and see what issue I can uncover before conducting research with participants. This allows me to see if users are discovering the same problems I did. I decided to use the <b>Nielsen Norman Group’s 10 usability heuristics for user interface design</b> and would create a Heuristic Evaluation list that clearly shows my findings. 
                    </p>
                    <p>
                        <h3><b>User Interviews</b></h3>
                        User interviews are very important for this study because it allows me to get direct opinions from actual users on what they think about Reels. I analyzed the interviews by creating an affinity map using the program <b>Miro</b> in order to find similar views and opinions as well as any pain points or frustrations. By doing this, we can see if users have a positive or negative outlook on Reels and what to focus on in order to bring in more users. 
                    </p>
                    <p>
                        <h3><b>Think-Aloud Usability Testing</b></h3>
                        Think-Aloud Usability Testing not only shows how quickly and easily users can complete tasks and reach their goals, but we can also learn about what the users are thinking and feeling during the whole process. This can show us what we need to fix and improve in Reels to make it a more satisfying experience.  
                    </p>
                </ul>
                <ul>
                    <p>
                        <h3><b>Project Plan</b></h3>
                        After figuring out what research methods I was going to use, I wrote my project plan, decided on my target audience, and wrote up interview protocols. 
                    </p>
                </ul>
                    <embed src="/assets/files/Timothy_Lee_Instagram_Reels_Project_Proposal.pdf" width="1050" height="800" />
                <ul>
                    <p>
                    My target audience and the people I wanted to use for my study was simply people who had an Instagram account, uses Instagram at least twice a week, and for the usability tests, people who haven’t used Reels before. I did this because I wanted to get a wide range of opinions from different kinds of users because there could be a difference in opinion based on groups. They are all definitely using Instagram, but how are their viewpoints different? For example, people of different age groups could have differing opinions and Instagram can recognize that they are missing out on a whole group of people that potentially could be using Reels, but isn’t yet. 
                    </p>
                </ul>
                <ul>
                    <p>
                        <h3><b>Interview Findings</b></h3>
                        The affinity map showed that almost all of the users believed that Reels was a good and necessary addition to Instagram. They had a positive reaction to the ability to edit video clips together and add effects and other features. They talked about how the other Instagram functionalities didn’t have this ability so it was good that they added it to Reels. However, users who were already familiar with TikTok believe that Reels is not as good yet. They think that TikTok still has better features and filters, such as the time warp filter and the Disney filter, that Reels didn’t have yet. 
                    </p>
                    <p>
                        I believed in order to resolve this a competitive analysis is needed to compare TikTok with the current version of Reels to determine what it is doing well so that we can improve Reels. Using competitive analysis can help Instagram find out what the target audience wants when it comes to making videos like this and where TikTok is succeeding and they are failing.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/Research/Reels/reels_affinity.jpg" alt="Reels Interview Affinity Map"/>
            <div className="body">
                <ul>
                    <p>
                        Another interesting insight I discovered is that all of my participants didn’t feel that they were worthy enough to make a Reel. What’s interesting was that it didn’t matter what age group the users were in; they all had some feeling that their life wasn’t interesting enough to make a Reel. The older group said Reels was more for the younger generation and not for them and the younger group said that Reels was more for influencers or people with many followers so they felt it wasn’t for them either.
                    </p>
                    <p>
                        I found this very interesting and that Instagram could increase their number of users if they promoted Reels in a way that shows that Reels is for everyone. Possibly showing them that even your ordinary life can look extraordinary when it is edited in Reels or something of that sort. I believe there is a huge number of potential users that Instagram could reach if they changed their approach at how they promote Reels. 
                    </p>
                </ul>
                <ul>
                    <p>
                        <h3><b>Personas</b></h3>
                        I took my findings and made 2 personas to help stakeholders understand what kind of users I discovered during my study.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/Research/Reels/reels_personas.jpg" alt="Reels Personas"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>Heuristic Evaluation and Think-Aloud Usability Test Results</b></h3>
                        The Reels functionality has some issues with #7 and #10 of the 10 usability heuristics for user interface design. Basically, it seemed like Reels was made for people who already know how to use a functionality similar to it. Which means, it was designed for people were already familiar with TikTok, but if you have never used TikTok before, there is little flexibility and you just need to suck it up and learn how to use it. If you wanted to learn how to use it through a tutorial or step by step directions, it’s not easy to get to. You’d have to exit Reels, go to your homepage, click on settings, go to help, and then find the help on Reels.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/Research/Reels/reels_heuristic.jpg" alt="Reels Heuristic Evaluation"/>
            <div className="body">
                <ul>
                    <p>
                    After the think-aloud usability tests, I found that my heuristic evaluation pretty much matched what the users thought. My participants were a mix of people who frequently post on their feed or stories, people who barely post anything, and people who have experience with TikTok. But all of them have never used Reels yet. So, this was their first time actually diving into the new functionality. I made a customer journey map for each of my participants because I thought it was important to show not only common patterns but also their unique experiences and issues they faced. 
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/Research/Reels/reels_journey.jpg" alt="Reels Journey Maps"/>
            <div className="body">
                <ul>
                    <p>
                    The participants all seemed to have struggled in the same area which was the editing phase. Most of them were all just confused. They commented that even though Reels looked similar to Stories, another Instagram functionality, it was still confusing to them. I believe it was due to the fact that Reels is non-linear. It doesn’t really have a step by step approach to making a Reel. You need to go back and forth to different pages in order to do different things like trim a clip or re-record or add stickers and music. Many of the users wanted to choose their music after they recorded their video but couldn’t find the music button in the editing page. It was because it the music button is located in the first page before they record their video. So, they have to go back to that page in order to add music. There’s a lot of back and forth going on that users don’t seem to be accustomed to. 
                    </p>
                </ul>
                <ul>
                    <p>
                        <h3><b>Results</b></h3>
                        Reels is a great new addition to Instagram, but needed to change up the way they advertise it to reach the average joe. Reels is being used by many influencers and people who are striving to get more followers, but there was an untapped well of average joe users who could also be using Reels, if Instagram is able to show that it’s a functionality for them as well. 
                    </p>
                    <p>
                    Instagram also needed to fix and improve on the whole Reels experience. They needed to fix bugs, make it easier to use for new users, and keep adding filters and effects that are unique and cool so that they can compete with TikTok and any other future competitor. 
                    </p>
                </ul>
                <embed src="/assets/files/Timothy_Lee_Instagram_Reels_Final_Report.pdf" width="1050" height="800" />
                <ul>
                    <p>
                        <h3><b>Side Note</b></h3>
                        Since this is a student project and I am not actually working for Instagram, but I found it very interesting that during my study, I was witnessing some of the issues that I discovered were being fixed in real-time. For example, I mentioned that one user didn’t know where to go after recording his video. This was because the button to go to the next stage was just a button with an arrow, it wasn’t clear enough to him that he was supposed to tap that button next. A week later, when I was testing someone else, I noticed that that button was changed to an arrow that also had the word “edit” next to it. Instagram found the same issue I did and fixed it! I thought that was pretty cool!
                    </p>
                </ul>
            </div>
        </ProjectReelsStyled>
    );
}

export default ProjectReels;

const ProjectReelsStyled = styled.div`
    
    font-family:"Raleway";
    font-size: 30px;

    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
    }

    h2 {
        font-family: "Raleway";
        font-size: 60px;
        margin: 50px 350px 200px 350px;
        color: dodgerblue;
    }

    .intro {
        margin: 200px 350px 0px 350px;
        font-size:35px;
    }

    .body {
        margin: 100px 350px 100px 350px;
        font-size: 30px;
            
            h3 {
                font-family: "Helvetica";
                font-size: 30px;
            }
    }
`;