import React from 'react';
import styled from 'styled-components';

const ProjectFischbach = () => {

    return (
        <ProjectFischbachStyled className='ProjectFischbach'>
            <h2>Fischbach Experience Center</h2>
            <div className="intro">
                <ul>
                    <p>
                        <b>Buying and selling cars can be difficult and frustrating. Some people might want to skip the dealership and just purchase their vehicle online. For this project my team and I focused on changing the way car buyers view dealerships and giving a more human experience to help them feel more at ease when purchasing a car.</b>
                    </p>
                </ul>
            </div>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>What's Going On</b></h3>
                        Since the rise of online shopping brick and mortars have been struggling to stay relevant. Unfortunately, the COVID pandemic worsened the situation and now brick and mortars need to find ways to bring customers back. The automotive sales industry is one industry that is in this certain predicament.
                    </p>
                </ul>
            </div>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>Going Through the Research</b></h3>
                        After our first-round research using assumption grids, empathy maps, and initial interviews we came up with a problem statement that helped guide us to a certain goal. 
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ServiceDesign/fisch_assumpempath.jpg" alt="Assumption Grid and Empathy Map" />
            <div className="body">
                <ul>
                    <p>
                        <h3><b>The Problem</b></h3>
                        Dealerships need to modernize because they fail to educate customers and take an archaic approach to sales and service.
                    </p>
                </ul>
                <ul>
                    <p>
                        However, after doing more research, creating personas and journey maps, organizing our data and putting it all on our research wall we realized that we needed to update our problem statment.
                    </p>
                </ul>
                </div>
                <img src="/assets/img/Work/ServiceDesign/fisch_journey.jpg" alt="Journey Maps" />
                <div className="body">
                <ul>
                    <p>
                        <h3><b>Updated Problem</b></h3>
                        Dealerships need to modernize because they fail to build trust, don’t engage customers, and take a time-consuming approach to sales and service.
                    </p>
                </ul>
            </div>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>What Does It Mean?</b></h3>
                        Though we had our problem statement we were still having trouble figuring out what it really meant. After debating and arguing about what the actual problem was we decided to use <b>The 5 Why's to find the roots of the problems</b> that we needed to focus on.
                    </p>
                </ul>
                </div>
                <img src="/assets/img/Work/ServiceDesign/fisch_5whys.jpg" alt="The 5 Whys" />
                <div className="body">
                <ul>
                    <p>
                        <h3><b>Let the Ideas Flow</b></h3>
                        We used the results from our 5 Why's to start ideating. The goal was to come up with as many ideas as possible and organize them. One of the biggest obstacles was to not rely on just making an app and actually trying to target the physical and human interaction and how to improve the overall experience at a dealership. 
                    </p>
                    <p>
                        We used <b>Crazy 8's</b>, <b>Journey Map Ideation</b>, and <b>Octopus Clustering</b> to generate ideas and organize them.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ServiceDesign/fisch_ideation.jpg" alt="Ideation Process" />
            <div className="body">
                <p>
                    <h3><b>Struggling to Narrow It Down</b></h3>
                    We had issues narrowing down what we wanted to focus on because of disputes and contribution issues between team members. We decided to organize all the ideas into three categories and used <b>Dot Voting</b> to decided on what ideas to continue with and came up with our solution.
                </p>
                <p>
                    <h3><b>Solution</b></h3>
                    In order to change the buyers' negative perception of the car dealership and entice them to choose buying in person over online shopping, we believe that the dealership should be changed into an <b>Experience Center</b>. Our solution consists of <b>five key components</b> that are part of the Experience Center transformation. We began prototyping the ideas we came up with for each component.
                </p>
            </div>
            <img src="/assets/img/Work/ServiceDesign/fisch_eccomponents.jpg" alt="Experience Center Components" />
            <div className="body">
                <ul>
                    <p>
                        <h3><b>The Fischbach Experience Center</b></h3>
                        The Fischbach Experience Center will shift the buyer perceptions of purchasing a car by experiencing a new area that has been reimagined for warmth and comfort. It partners with notable brands to create a more immersive experience and allows buyers to browse at their own pace using our digital engagement system. Test driving has been changed to give buyers more independence and adventure with solo test driving and different terrain options. It's an immersive center that gives buyers a comfortable, informative and interactive atmosphere that will motivate them to purchase the right car.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ServiceDesign/fisch_prototypes.jpg" alt="prototypes" />
            <div className="body">
                <ul>
                    <p>
                        <h3><b>Results</b></h3>
                        While the overall reaction was poisitive, we found that some components were more preferred than others and some fell flat. Quantitative research showed that the three best features that users were excited about were the <b>Solo Test-Drive</b>, <b>Rooftop Lounge</b>, and <b>Digital Kiosks</b>. We hope that this project will inspire dealerships to make some changes and improve their buisnesses.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ServiceDesign/fisch_results.jpg" alt="results" />
        </ProjectFischbachStyled>
    );
}

export default ProjectFischbach;

const ProjectFischbachStyled = styled.div`
    background-color: #DCDCDC;
    font-family: "Raleway";
    font-size: 30px;

    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
    }

    h2 {
        font-family: "Raleway";
        font-size: 60px;
        margin: 50px 0px 200px 210px;
        color: dodgerblue;
    }

    .intro {
        margin: 200px 350px 0px 350px;
        font-size: 35px;
    }

    .body {
        margin: 100px 350px 100px 350px;
        font-size: 30px;
            
            h3 {
                font-family: "Helvetica";
                font-size: 30px;
            }
    }

`;