import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const SiteTitle = () => {



    return (
        <SiteTitleStyled className='SiteTitle'>
            <NavLink to={ '/' } exact><h2>Timothy Lee</h2></NavLink>
        </SiteTitleStyled>
    );
}

export default SiteTitle;

const SiteTitleStyled = styled.div`
    background-color: #DCDCDC;
    color: dodgerblue;
    text-align: center;

    h2 {
        margin: 0px;
        font-size: 40px;
        font-weight: normal;
        text-transform: uppercase;
        font-family: "Raleway";
    }

    a {
            color: dodgerblue;
            text-decoration: none;
    }
`;