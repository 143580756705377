export const tabbedData = [
    {
        image: '/assets/img/About/Timportpic.jpg',
        title: 'About Me',
        text: "<p>After teaching English abroad for over 10 years, I've decided that it was time to take a leap and make a career change. I discussed my life change with my family and friends and decided to embark on becoming an UX designer. I feel that with my experience as a teacher and my overall interests and skills, I will be a very effective designer. </p><p> I studied and received my User Experience Certification at UCLA Extension. In the UCLA UX program I have worked on various projects and real-world case studies that have prepared me for a future career the field. Along with UX design, I was also able to learn UX research, Content Strategy, and took a frontend coding bootcamp to broaden my skillset.</p><p> I am currently working freelance on two different projects for small companies to gain some experience before embarking into a bigger opportunity. Through the freelance work, I am able to practice the methodology I learned and experience real-world situations and issues that I didn't encounter in classes.</p><p> I am ready to start this new chapter in my life and am excited to work in UX!",
    },
    {
        image: '/assets/img/About/Timportpic.jpg',
        title: 'Resume',
        text: '<embed src="/assets/files/TimothyLeeUXResume.pdf" width="1050" height="800" />',
    },
    {
        image: '/assets/img/About/Timportpic.jpg',
        title: 'Contact',
        text: '<p><b>You Can Reach Me At:</b></p><p><b>Email: </b><a href="mailto:uxtimlee@gmail.com">uxtimlee@gmail.com</a></p><p><a href="https://www.linkedin.com/in/tim-lee-26481281"> <img class="logos" src="/assets/img/About/768px-LinkedIn_logo_initials.png" alt="LinkedIn Link" width="80" height="80" /></a> ',
    },
];