import React from 'react';
import styled from 'styled-components';

const ProjectAirbnb = () => {

    return (
        <ProjectAirbnbStyled className='ProjectAirbnb'>
            <h2>Airbnb</h2>
            <div className="intro">
                <ul>
                    <p>
                        <b>Airbnb has grown to become one of the biggest hospitality marketplaces in the world, with 5.6 million listings and a $90 billion market value. They use an agency model that charges a fee from both hosts and guests for their services.</b>
                    </p>
                </ul>
            </div>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>The Problem</b></h3>
                        Airbnb faces many problems and spend millions of dollars trying to make them go away. They want to build stronger trust so that guests can feel safe and comfortable, and hosts can provide accomodations without any concerns.
                    </p>
                    <p>
                        However, <b>there is a lack of balance between guests and hosts</b>. Unpredictable factors that are out of the guests/hosts hands can cause distrust and Airbnb tends to favor the guests over the hosts during conflict.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ServiceDesign/airbnb_problems.jpg" alt="Problem"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>First Attempt: Damage Deposit Integration</b></h3>
                        The first solution to build better trust was to create a damage deposit for the hosts that guests can pay and get back as long as there are no damages to the property. According to the user interviews users liked the solution but some were hesitant because they feel hosts have the potential to scam them out of their deposits. However, they also felt it would help them identify deceptive and untrustworthy listings. Hosts also believed that adding deposits would change the kind of guests who use Airbnb. It could deter guests who just want a place to party.
                    </p>
                    <p>
                        During the prototype build, it was revealed that Airbnb <b>already has a security deposit system</b>. The interesting part of this mistake is that none of the users mentioned that there was already a security deposit. So, maybe hosts are not integrating them or there might be some other issue that needs focused on later.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ServiceDesign/airbnb_deposit.jpg" alt="Deposit"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>New Solutions</b></h3>
                        Returing to ideation, the main question was still "How do we help gain more trust and balance?" Two solutions were propsed: <b>A guest history book and Superguest status</b>. 
                    </p>
                    <p>
                        The guest history book would show guests' previous visits and other information that would be useful for help the host decide on whether to accept or decline the guests' requests. It only shows where the guests have gone, what issues the previous hosts had, and how there were resolved, if there were any issues. The user interviews showed that this would be helpful and that it's <b>more trustworthy because it is more unbiased than reviews</b> because the history would only show the facts. The users seemed to be on board as long as the history didn't show too much unnecessary information. 
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ServiceDesign/airbnb_history.jpg" alt="History"/>
            <div className="body">
                <ul>
                    <p>
                        Hosts have a Superhost status that keeps them in check and makes sure that they are providing good rentals and service, so it would be useful to also include a Superguest status. It would motiveate guests to stay out of trouble in order to gain an upper hand in getting accepted. User interviews showed that this idea was viewed favorably because it's fair and mentioned that other popular apps, such as Uber, are already doing something similar. It would <b>reassure hosts of guests who have a clean record and speed up the acceptance process for guests</b>.
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ServiceDesign/airbnb_superguest.jpg" alt="Superguest"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>On the Fence</b></h3>
                        one final solution what was considered was to charge an equal fee for both guests and hosts. Currently, Airbnb charges a hight fee to guests and since they are receiving more money from guests they are giving guests more attention. If Airbnb were to take an equal percentage from both guests and hosts when a rental is booked, it would motivate Airbnb to give them an equal amount of attention. Because of Airbnb's strong brand and the euqal fee adjustment will not be too much higher than the competition, it will not driver users away. 
                    </p>
                </ul>
            </div>
            <img src="/assets/img/Work/ServiceDesign/airbnb_equalfee.jpg" alt="Equal Fees"/>
            <div className="body">
                <ul>
                    <p>
                        <h3><b>Final Thoughts</b></h3>
                        While the Guest History and the Superguest solutions gain a lot of support the Equal Fees solution seems to be controversial with many people doubting its effectiveness. More research is needed to see if it would actually be beneficial for Airbnb. 
                    </p>
                </ul>
            </div>
        </ProjectAirbnbStyled>
    );
}

export default ProjectAirbnb;

const ProjectAirbnbStyled = styled.div`

font-family:"Raleway";
    font-size: 30px;

    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
    }

    h2 {
        font-family: "Raleway";
        font-size: 60px;
        margin: 50px 350px 100px 350px;
        color: dodgerblue;
    }

    .intro {
        margin: 100px 350px 0px 350px;
        font-size: 35px;
    }

    .body {
        margin: 100px 350px 100px 350px;
        font-size: 30px;
            
            h3 {
                font-family: "Helvetica";
                font-size: 30px;
            }
    }
`;